<template>
  <div>
    <!-- modal vertical center -->
    <b-modal
      id="add-lesson"
      ref="add-lesson"
      centered
      title="Vertically Centered"
      hide-footer
    >
      <b-card-text>
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Name"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="newRecord.displayName"
                  placeholder="The name to display in the address book for the user."
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Description"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="newRecord.description"
                  type="text"
                  placeholder="description"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" v-if="!update_record.isUpdate">
              <b-form-group
                label="mail Nickname"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="newRecord.mailNickname"
                  placeholder="The mail alias for the user."
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="mailEnabled"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-checkbox
                  v-model="newRecord.mailEnabled"
                  :value="true"
                  :unchecked-value="false"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="securityEnabled"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-checkbox
                  v-model="newRecord.securityEnabled"
                  :value="true"
                  :unchecked-value="false"
                />
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                @click="addNewRecord"
              >
                {{ $t('Submit') }}
              </b-button>
              <b-button
                variant="outline-secondary"
                @click="resetForm"
              >
                {{ $t('Reset') }}
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </b-card-text>
    </b-modal>

    <!-- input search -->
    <b-row class="mb-2">

      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-md-0"
      >
        <div class="custom-search d-flex justify-content-center">
          <b-form-group>
            <div class="mb-1">
              <b-button
                v-b-modal.add-lesson
                variant="secondary"
                @click="$router.go(-1)"
              >
                <feather-icon
                  icon="ArrowLeftCircleIcon"
                  class="mr-50"
                />
                <span>{{ $t('Back') }}</span>
              </b-button>
            </div>
            <div class="">
              <b-button
                v-b-modal.add-lesson
                variant="primary"
                @click="resetForm"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                {{ $t('Add Group') }}
              </b-button>

            </div>

          </b-form-group>

        </div>

      </b-col>

      <!-- Search -->
      <b-col
        cols="12"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block"
            placeholder="Search..."
          />
        </div>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      style-class="vgt-table bordered striped"
      :line-numbers="true"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      @on-cell-click="redirectToMember"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'displayName'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.displayName }}</span>
        </span>

<!--          <span v-else-if="props.column.field === 'proxyAddresses'">-->
<!--              <span v-if="props.row.proxyAddresses.length > 0">-->
<!--                  <span v-if="props.row.proxyAddresses[props.row.proxyAddresses.length - 1].length > 0">-->
<!--                      {{props.row.proxyAddresses[props.row.proxyAddresses.length - 1].slice(5)}}-->
<!--                  </span>-->
<!--                  <span v-else>-->
<!--                      {{props.row.proxyAddresses[props.row.proxyAddresses.length - 1]}}-->
<!--                  </span>-->
<!--              </span>-->
<!--              <span v-else>Empty</span>-->
<!--        </span>-->


          <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant('Active')">
            {{ `Active` }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item v-b-modal.add-lesson @click="openFormUpdate(props)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteRecord(props.row)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BFormCheckbox
} from "bootstrap-vue";
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Swal from "sweetalert2";

export default {
    name: 'List',
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BFormCheckbox
    },
    data() {
        return {
            pageLength: 20,
            dir: false,
            columns: [
                {
                    label: 'Name',
                    field: 'displayName',
                },
                {
                    label: 'Description',
                    field: 'description',
                },
                {
                    label: 'Mail Group',
                    field: 'mail',
                },
                // {
                //     label: 'Proxy Addresses',
                //     field: 'proxyAddresses',
                // },
                {
                    label: 'Mail Enabled',
                    field: 'mailEnabled',
                },
                {
                    label: 'Mail Nickname',
                    field: 'mailNickname',
                },
                {
                    label: 'Security Enabled',
                    field: 'securityEnabled',
                },
                {
                    label: 'Create',
                    field: 'createdDateTime'
                },
                // {
                //     label: 'Status',
                //     field: 'status',
                // },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            status: [],
            newRecord: {
                mailEnabled: true,
                displayName: '',
                mailNickname: '',
                securityEnabled: true,
                groupTypes: [
                    'Unified',
                ],
                description: '',
            },
            update_record: {
                isUpdate: false,
                id: '',
            },
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                Current      : 'light-primary',
                Active : 'light-success',
                Rejected     : 'light-danger',
                Resigned     : 'light-warning',
                Applied      : 'light-info',
                /* eslint-enable key-spacing */
            }

            return (status) => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    async created() {
        await this.$swal_show()
        await this.getListUser()
        await this.$swal_hide()
    },
    methods: {
        async getListUser() {
            this.rows = []
            await this.$http.post('/third-party/list-group')
                .then((response) => {
                    const { data, message, success } = response.data
                    if (success) {
                        this.rows = data.value
                    } else {
                        this.$error(message)
                    }
                })
                .catch((error) => {
                    const status = _.get(error, 'response.status', 'unknown')
                    const reason = _.get(error, 'response.statusText', 'unknown')
                    const message = _.get(error, 'response.data.message', 'unknown')

                    if (_.isArray(message)) {
                        message.forEach((msg) => {
                            this.$error(msg)
                        })
                    } else {
                        this.$error(message)
                    }
                })
        },
        resetForm() {
            this.newRecord = {
                mailEnabled: true,
                displayName: '',
                mailNickname: '',
                securityEnabled: true,
                groupTypes: [
                  'Unified',
                ],
                description: '',
            }
        },
        hideModal(modalID) {
            this.$refs[modalID].hide()
        },
        confirmText() {
            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.$swal({
                        icon: 'success',
                        title: 'Deleted!',
                        text: 'Your file has been deleted.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                }
            })
        },
        openFormUpdate(props) {
            this.update_record.isUpdate = true
            this.update_record.id = props.row.id
            this.newRecord = {
                mailEnabled: props.row.mailEnabled,
                displayName: props.row.displayName,
                mailNickname: props.row.mailNickname,
                securityEnabled: props.row.securityEnabled,
                groupTypes: [
                    'Unified',
                ],
                description: props.row.description,
            }
        },
        async sleepWaiting(ms) {
            return new Promise((resolve) => {
                setTimeout(resolve, ms)
            })
        },
        async addNewRecord() {
            // this.$swal_show(5000)
            if (this.update_record.isUpdate) {
                await this.updateRecord(this.update_record.id, this.newRecord)
            } else {
                await this.$http.post('/third-party/add-group', this.newRecord)
                    .then(async (res) => {
                        const { data, message, success } = res.data
                        if (success) {
                            this.hideModal('add-lesson')
                            this.$swal({
                                title: 'Waiting...',
                                text: 'The system is creating a group, please wait a few minutes!',
                                icon: 'info',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                showClass: {
                                    popup: 'animate__animated animate__tada',
                                },
                                buttonsStyling: false,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                timer: 60000,
                                didOpen(popup) {
                                    Swal.showLoading()
                                },
                                timerProgressBar: true,
                            })
                            // await this.getListUser()
                            await this.sleepWaiting(60000)
                            this.$success('Add new group success')
                            await this.getListUser()

                            // this.$swal({
                            //     title: 'Good job!',
                            //     text: 'Add new group success!',
                            //     icon: 'success',
                            //     customClass: {
                            //         confirmButton: 'btn btn-primary',
                            //     },
                            //     showClass: {
                            //         popup: 'animate__animated animate__tada',
                            //     },
                            //     buttonsStyling: false,
                            // })
                            // this.$swal_hide()
                        } else {
                            this.$error(message)
                            // this.$swal_hide()
                        }
                    }).catch((error) => {
                        // this.$swal_hide()
                        const status = _.get(error, 'response.status', 'unknown')
                        const reason = _.get(error, 'response.statusText', 'unknown')
                        const message = _.get(error, 'response.data.message', 'unknown')

                        if (_.isArray(message)) {
                            message.forEach((msg) => {
                                this.$error(msg)
                            })
                        } else {
                            this.$error(message)
                        }
                    })
            }
        },
        async deleteRecord(record) {
            await this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(async (result) => {
                if (result.value) {
                    this.$swal_show()
                    await this.$http.post('/third-party/delete-group', { group_id: record.id })
                        .then(async (res) => {
                            const { data, message, success } = res.data
                            if (success) {
                                await this.getListUser()
                                this.$success('Delete success')
                            } else {
                                this.$error(message)
                            }
                        }).catch((error) => {
                            const status = _.get(error, 'response.status', 'unknown')
                            const reason = _.get(error, 'response.statusText', 'unknown')
                            const message = _.get(error, 'response.data.message', 'unknown')

                            if (_.isArray(message)) {
                                message.forEach((msg) => {
                                    this.$error(msg)
                                })
                            } else {
                                this.$error(message)
                            }
                        })
                    this.$swal_hide()
                    this.$swal({
                        icon: 'success',
                        title: 'Deleted!',
                        text: 'Your file has been deleted.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                } else if (result.dismiss === 'cancel') {
                    this.$swal({
                        title: 'Cancelled',
                        text: 'Your imaginary file is safe :)',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                }
            })
        },
        async updateRecord(id, update) {
            await this.$http.post('/third-party/update-group', { group_id: id, group: update })
                .then((res) => {
                    const { data, message, success } = res.data
                    if (success) {
                        this.update_record.isUpdate = false
                        this.update_record.id = ''
                        this.getListUser()
                        this.hideModal('add-lesson')
                        this.$success('Update success')
                    } else {
                        this.$error(message)
                    }
                }).catch((error) => {
                    const status = _.get(error, 'response.status', 'unknown')
                    const reason = _.get(error, 'response.statusText', 'unknown')
                    const message = _.get(error, 'response.data.message', 'unknown')

                    if (_.isArray(message)) {
                        message.forEach((msg) => {
                            this.$error(msg)
                        })
                    } else {
                        this.$error(message)
                    }
                })
        },
        async redirectToMember(params){
            if (params.column.field !== 'action') {
                await this.$router.push({ name: 'list-member-group-page', query: { group_id: params.row.id } })
            }
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.back-btn {
  margin-bottom: 14px;
}
</style>
